import { FC } from "react";

import { VStack, Box } from "@chakra-ui/react";

import Checkbox from "@components/Checkbox";
import { Typography } from "@components/common";

import { TCookieConsentOptionsProps } from "../types";
import CookieConsentExpand from "./CookieConsentExpand";
import CookieConsentGroupList from "./CookieConsentGroupList";

const cookieGroupDescriptionStyle = { mb: "0.875rem", mt: "0.3125rem", fontWeight: "light" };
const cookieGroupTitleStyle = { fontSize: "1rem", fontWeight: "bold" };
const getKey = (index: number): string => `cookie-consent-option-${index}`;
const getListKey = (index: number): string => `cookie-consent-group-description-list-${index}`;

const CookieConsentOptions: FC<TCookieConsentOptionsProps> = ({ children, onChange, currentCookie, cookieOptions }) => (
  <>
    <Typography sx={{ fontWeight: "light", mb: "0.625rem" }}>{children}</Typography>
    <VStack spacing="1.25rem" alignItems="start">
      {cookieOptions.map(
        ({ cookieGroupTitle, cookieGroupDescription, isDisabled, isChecked, value, cookieGroupList }, index) => (
          <Box key={getKey(index)}>
            <Checkbox
              value={value.toString()}
              isFormik={false}
              defaultIsChecked={(currentCookie?.includes(value.toString())) || currentCookie?.toString() === "true"}
              onChange={(e) => onChange(e.target.value)}
              {...(isChecked && { isChecked: true })}
              {...(isDisabled && { isDisabled: true })}
            >
              <Typography sx={cookieGroupTitleStyle}>{cookieGroupTitle}</Typography>
            </Checkbox>
            <Typography sx={cookieGroupDescriptionStyle}>{cookieGroupDescription}</Typography>
            {!!cookieGroupList.length && (
              <CookieConsentExpand>
                {cookieGroupList.map(({ header, cookieList }, listIndex) => (
                  <CookieConsentGroupList key={getListKey(listIndex)} header={header} cookieList={cookieList} />
                ))}
              </CookieConsentExpand>
            )}
          </Box>
        )
      )}
    </VStack>
  </>
);

export default CookieConsentOptions;
