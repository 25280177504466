import { FC, useState } from "react";

import { useTranslation } from "next-i18next";

import Modal from "@components/Modal";
import getConfig from "@modules/cookie-consent/config";

import { getConsentCookie, setConsentCookie } from "../utils/cookieActions";
import CookieConsentOptions from "./CookieConsentOptions";

const CookieConsentModal: FC<{
  isOpen?: boolean;
  onClose?: () => void;
  onChange: (value: string | boolean) => void;
  selectedCookies: string | boolean;
  isCookieOptionsVisible?: boolean;
  id: string;
}> = ({ isOpen, onClose = () => {}, onChange, selectedCookies, isCookieOptionsVisible, id }) => {
  const [shouldShowCookieOptions, setShouldShowCookieOptions] = useState(isCookieOptionsVisible);
  const { t } = useTranslation();
  const cookieOptions = getConfig();
  const handleUtilityButtonClick = () => {
    if (!shouldShowCookieOptions) {
      setShouldShowCookieOptions(true);
      return;
    }
    setConsentCookie(selectedCookies);
    onClose();
  };

  const handleRejectButtonClick = () => {
    setConsentCookie(false);
    onClose();
    onChange(false);
  };
  const handleSubmitButtonClick = () => {
    setConsentCookie(true);
    onClose();
    onChange(true);
  };

  let currentCookie = getConsentCookie();
  let currentCookieArray: string[] | number = [];
  if (currentCookie !== "false" && currentCookie !== "true" && typeof currentCookie !== 'undefined') {
    currentCookieArray = JSON.parse(currentCookie);
  } else if (typeof currentCookie !== 'undefined' && (currentCookie === 'true' || currentCookie === 'false')) {
    currentCookieArray.push(currentCookie);
  }
  if (typeof currentCookieArray === 'number') {
    currentCookieArray = [currentCookieArray.toString()];
  }

  return (
    <Modal
      hasHeader
      hasSubmitButton
      hasRejectButton
      hasUtilityButton
      headerText={shouldShowCookieOptions ? t("cookie_consent-settings-title") : t("cookie_consent-alert-title")}
      isOpen={isOpen}
      hasCloseButton={false}
      onSubmit={handleSubmitButtonClick}
      onReject={handleRejectButtonClick}
      onUtilityClick={handleUtilityButtonClick}
      utilityLabel={
        shouldShowCookieOptions ? t("cookie_consent-button-save_settings") : t("cookie_consent-button-settings")
      }
      submitLabel={t("cookie_consent-button-accept_all")}
      rejectLabel={t("cookie_consent-button-reject_all")}
      id={id}
    >
      {shouldShowCookieOptions ? (
        <CookieConsentOptions onChange={onChange} currentCookie={currentCookieArray} cookieOptions={cookieOptions}>
          {t("cookie_consent-settings-text")}
        </CookieConsentOptions>
      ) : (
        t("cookie_consent-alert-text")
      )}
    </Modal>
  );
};

export default CookieConsentModal;
