import Cookies from "js-cookie";

import { COOKIE_CONSENT } from "@modules/features";
import { TFeatures } from "@modules/features/types";

import { CONSENT_COOKIE, GTM_EVENT } from "./constants";

declare const gtag: (...args: any[]) => void;

export const checkIfConsentCookieExists = (): boolean => !!Cookies.get(CONSENT_COOKIE);

export const getConsentCookie = (): string | undefined => Cookies.get(CONSENT_COOKIE);

export const setConsentCookie = (value: boolean | string): void => {
  const expires = new Date();
  expires.setFullYear(expires.getFullYear() + 1);
  Cookies.set(CONSENT_COOKIE, value.toString(), { expires, sameSite: "strict" });

  //  Fire GTM event if dataLayer is found
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({ event: GTM_EVENT });
    const cookieArray: (string | boolean)[] = [];
    if (value === true || value === false) {
      cookieArray.push(value);
    } else {
      cookieArray.push(...JSON.parse(value));
    }

    gtag('consent', 'update', {
      'ad_storage': (cookieArray.includes(true) || cookieArray.includes('3')) ? 'granted' : 'denied',
      'analytics_storage': (cookieArray.includes(true) || cookieArray.includes('2')) ? 'granted' : 'denied',
      'ad_personalization': (cookieArray.includes(true) || cookieArray.includes('4')) ? 'granted' : 'denied',
      'ad_user_data': (cookieArray.includes(true) || cookieArray.includes('3')) ? 'granted' : 'denied',
      'functionality_storage': 'granted',
      'personalization_storage': (cookieArray.includes(true) || cookieArray.includes('4')) ? 'granted' : 'denied',
      'security_storage': 'granted',
    });
  }
};

export const checkIfCookieFeatureEnabled = (features: TFeatures): boolean => features?.includes(COOKIE_CONSENT);
