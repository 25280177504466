import { FC } from "react";

import { VStack, Box, useBreakpointValue, Flex } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import dynamic from "next/dynamic";
import Head from "next/head";

import Message, { ContextMessage } from "@components/Message";
import { useFbPixel } from "@lib/fbPixel";
import { Desktop, Mobile } from "@lib/media";
import { RecaptchaProvider } from "@lib/recaptcha";
import colors from "@lib/theme/colors";
import { checkIfChatBotEnabled } from "@lib/webchat/utils/chatBotActions";
import { useFeatureData } from "@modules/features";
import { LAYOUT } from "@utils/constants";
import getEnv from "@utils/getEnv";

import Footer from "../Footer";
import Header from "../Header";
import { TLayout } from "./types";
import { useRouter } from "@lib/router";

const NextNProgress = dynamic(() => import("nextjs-progressbar"), { ssr: false });
const Webchat = dynamic(() => import("@lib/webchat"), { ssr: false });
const Scripts = dynamic(() => import("./Scripts"), { ssr: false });

const gtmCodes = {
  lt: 'GTM-MR68Z6X',
  lv: 'GTM-M5X5CB5',
  ee: 'GTM-TZR2X4T',
}

const Layout: FC<TLayout> = ({
  children,
  domain,
  hasMobileFooter = true,
  hasTagManager = true,
  hasWebchat: isWithWebchat = true,
  hasMobileWebchat: isWithMobileWebchat = true,
  hasRecaptcha = false,
}) => {
  const { t } = useTranslation();

  useFbPixel();

  const isDesktop = useBreakpointValue({ base: false, md: true });
  const shouldShowFooter = isDesktop || hasMobileFooter;
  const features = useFeatureData();
  const isChatBotEnabled = checkIfChatBotEnabled(features);

  const { defaultLocale } = useRouter();

  const hasWebchat = isChatBotEnabled && getEnv("NEXT_PUBLIC_USE_WEBCHAT") === "true" && !hasRecaptcha && isWithWebchat;
  const shouldLoadTagManager = getEnv("NEXT_PUBLIC_USE_GTM") !== "false";

  return (
      <RecaptchaProvider isEnabled={hasRecaptcha} domain={domain}>
        <Head>
          {hasTagManager && shouldLoadTagManager && defaultLocale && <script
              dangerouslySetInnerHTML={{
                __html: `
              (function(w,d,s,l,i){
                w[l]=w[l]||[];
                w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
                var f=d.getElementsByTagName(s)[0],
                    j=d.createElement(s),
                    dl=l!='dataLayer'?'&l='+l:'';
                j.async=true;
                j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
                f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','${gtmCodes[defaultLocale]}');
              
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              
              dataLayer.push({'gtm.start': new Date().getTime(), 'event': 'gtm.js'});
              
              function getCookie(name) {
                  var cookies = document.cookie.split(';');
                  var cookieValue = null;
                  cookies.forEach(cookie => {
                      var [cookieName, value] = cookie.split('=').map(c => c.trim());
                      if (cookieName === name) {
                          cookieValue = decodeURIComponent(value);
                      }
                  });
                  return cookieValue;
              }
              
              function parseCookie(cookie) {
                  try {
                      var parsedCookie = JSON.parse(cookie);
                      if (Array.isArray(parsedCookie)) {
                          return parsedCookie;
                      }
                  } catch (error) {
                      // If parsing as JSON fails, it might be a boolean
                  }
                  if (cookie === 'true' || cookie === 'false') {
                      return cookie === 'true';
                  }
                  return cookie;
              }
              
              var myCookie = getCookie('__cookie_consent');
              var parsedValue = parseCookie(myCookie);
              var cookieArray = [];
              if (parsedValue !== null) {
                  if (parsedValue === true || parsedValue === false) {
                      cookieArray.push(parsedValue);
                  } else {
                      cookieArray.push(...parsedValue);
                  }
              }
              
              if (parsedValue === null) {
                  gtag('consent', 'default', {
                      'security_storage': 'denied',
                      'functionality_storage': 'denied',
                      'ad_storage': 'denied',
                      'ad_user_data': 'denied',
                      'ad_personalization': 'denied',
                      'analytics_storage': 'denied',
                      'personalization_storage': 'denied',
                  });
              } else {
                  gtag('consent', 'default', {
                      'security_storage': 'granted',
                      'functionality_storage': 'granted',
                      'ad_storage': (cookieArray.includes(true) || cookieArray.includes('3')) ? 'granted' : 'denied',
                      'ad_user_data': (cookieArray.includes(true) || cookieArray.includes('3')) ? 'granted' : 'denied',
                      'ad_personalization': (cookieArray.includes(true) || cookieArray.includes('4')) ? 'granted' : 'denied',
                      'analytics_storage': (cookieArray.includes(true) || cookieArray.includes('2')) ? 'granted' : 'denied',
                      'personalization_storage': (cookieArray.includes(true) || cookieArray.includes('4')) ? 'granted' : 'denied',
                  });
              }
            `
              }}
          />}
          <meta name="viewport" content="width=device-width, initial-scale=1, minimum-scale=1"/>
          <noscript>
            <style>
              {`
              .noscript-hide {
                display: none;
              }
            `}
            </style>
          </noscript>
        </Head>
        { defaultLocale && <noscript
            dangerouslySetInnerHTML={{
              __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=${gtmCodes[defaultLocale]}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
            }}
        />}
        <NextNProgress color={colors.red.lead} options={{showSpinner: false}}/>
        <VStack minH="100vh" justifyContent="flex-start" alignItems="stretch" spacing="0">
          <Box position="sticky" top="0" left="0" right="0" zIndex="100" background="white">
            <Header domain={domain}/>
          </Box>

          <Flex direction="column" flexGrow={1} width="full">
            <noscript>
              <Message variant="danger" sx={{mb: "0.5rem"}} textBoxWidth="71.25rem" justify="center" noScript>
                {t("common-errors-browser_error_message")}
              </Message>
            </noscript>
            <ContextMessage messageZone={LAYOUT.MESSAGE_ZONE_LAYOUT}/>
            {children}
          </Flex>
          {shouldShowFooter && <Footer/>}
        </VStack>
        {/**
         *
         * Place for additional scripts and script content containers.
         *
         */}
        {hasWebchat && (
            <Desktop>
              <Webchat/>
            </Desktop>
        )}
        {hasWebchat && isWithMobileWebchat && (
            <Mobile>
              <Webchat/>
            </Mobile>
        )}

        <Scripts hasTagManager={hasTagManager}/>
      </RecaptchaProvider>
  );
};

export default Layout;
